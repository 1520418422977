<template>
  <header class="header-wrapper">
    <slot />
  </header>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.header-wrapper {
  width: 100%;
  height: 100px;
  background: #282828;
  padding-left: 120px;

  display: flex;
  align-items: center;
  .title {
    font-size: 30px;
    color: #fff;
    padding: 0 120px;
  }
}
</style>
